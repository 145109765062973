<template>
  <div class="view pa24">
    <el-row :gutter="24">
      <el-col :xl="24" :lg="16" class="d-flex justify-content-between w100p">
        <div class="d-flex align-items-center">
          <el-input
              clearable
              v-model="searchKey"
              class="mb10"
              style="width: 200px"
              placeholder="内容搜索"
          />
          <el-button class="ml10 mb10" type="primary" @click="search">搜索</el-button>
          <!-- <el-button class="ml10 mb10">导出</el-button> -->
        </div>
      </el-col>

    </el-row>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="nickeName" align="center" label="昵称" />
        <el-table-column  align="center" label="头像">
          <template slot-scope="scope">
            <el-image
                fit="cover"
                style="width: 40px; height: 40px;"
                :src="scope.row.avatarUrl"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="联系信息" />
        <el-table-column align="center" label="内容">
          <template slot-scope="scope">
            <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="提交时间" />
<!--        <el-table-column align="center" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button type="text" @click="detall_tap(scope.row.jobNumber)">详情</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {addUserFeedback,selectUserFeedbackList,upUserFeedback,delUserFeedback} from "@/api/setting";
import { getDataTimeSec } from "@/utils/index";
import { mapState } from 'vuex';
import {Base64 as base64} from "js-base64";
export default {
  name: "userFeedback",
  components: {
    commonTable,
  },
  data() {
    return {
      centerDialogVisible: false,
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      searchKey:'', //关键字
      userInfo: this.$store.state.loginRoot.userInfo,
      departmentList:[],
      ruleForm:{
        phone:'',
        details:''
      },
      setAdministrator:false, // 是否可设置管理员
      setDepartment:false,// 是否可设置部门
      srcList: [],
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{

  },
  mounted() {
    this.queryPage();
  },
  methods: {
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectUserFeedbackList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          if (list[i].nickeName) {
            list[i].nickeName = base64.decode(list[i].nickeName);
          }
          if (list[i].avatarUrl) {
            a.push(list[i].avatarUrl)
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        content:this.searchKey
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        content:this.searchKey
      };
      this.queryPage(data);
    },


    search(){
      this.currentPage = 1;
      let data = {
        content:this.searchKey
      };
      this.queryPage(data);
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
